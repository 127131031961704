import './styles/NewsBar.scss';
import { FC, lazy, useEffect, useRef, useState } from 'react';
import { useIsMobileNewUi } from '../../hooks/useIsMobileNewUi';
import { CountryUpdates } from '../Homepage/NewsSection';
import WithRtl from '../../shared/WithRtl';
import { useTranslation } from 'react-i18next';
import { useIsMobileShared } from '../../hooks/useIsMobileShared';

export type NewProps = {
  localNews?: CustomerUpdateLatest[] | null;
};
export type CustomerUpdateLatest = {
  Title: string;
  Date: string;
  PostUrl: string;
};

const NewsBar: FC<NewProps> = ({ localNews }) => {
  const [data, setData] = useState(localNews || []);
  const [showMore, setShowMore] = useState(false);
  const [showMoreLink] = useState(
    document?.getElementById('newsTickerReact').getAttribute('data-url') ?? ''
  );
  const newsTicker = useRef(null);
  const isMobile = useIsMobileShared(992);
  const RTL = lazy(() => import('./NewsBarRtl'));
  const { t } = useTranslation();
  const singleNewsItem = data && data.length && data.length == 1;

  const translations = {
    seeMoreUpdates: t('homepage.NewsSection.SeeAllNewsUpdates'),
    showMore: t('homepage.NewsSection.ShowMore'),
    showLess: t('homepage.NewsSection.ShowLess')
  };

  useEffect(() => {
    let firstItem = null;
    let intervalId = null;

    if (newsTicker.current && !singleNewsItem) {
      const animateTicker = () => {
        if (
          !showMore &&
          newsTicker.current &&
          newsTicker.current.children &&
          newsTicker.current.children.length
        ) {
          firstItem = newsTicker.current.children[0];
          firstItem.style.marginTop = '-' + firstItem.offsetHeight + 'px';
          // Listen for the transitionend event
          firstItem.addEventListener('transitionend', handleTransitionEnd);
        }
      };

      intervalId = setInterval(animateTicker, 4500);

      const handleTransitionEnd = () => {
        if (!showMore && firstItem) {
          firstItem.style.marginTop = '0';
          newsTicker?.current?.appendChild(firstItem);
          // Remove the event listener after the transition is complete
          firstItem.removeEventListener('transitionend', handleTransitionEnd);
        }
      };

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [showMore, singleNewsItem]);

  const formatDate = (dateString) => {
    if (!dateString) return;
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' }).toUpperCase();
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  function renderByDevice() {
    if (!data || data?.length == 0) return null;
    if (isMobile) {
      return (
        <div id="united">
          <div id="newsTicker" ref={newsTicker} className={`news_bar_container top close`}>
            {(data || []).map((newsItem, index) => (
              <div key={index} className="line_container">
                <div className="date">{formatDate(newsItem?.Date)}</div>
                <div className="line"></div>
                <a className="title" href={newsItem?.PostUrl}>
                  {newsItem?.Title}
                </a>
              </div>
            ))}
          </div>
          <a className="arrow" href={showMoreLink}></a>
        </div>
      );
    }
    return (
      <div id="united">
        <div
          id="newsTicker"
          ref={newsTicker}
          tabIndex={0}
          className={`news_bar_container top${showMore ? ' open' : ' close'}`}>
          {(data || []).map((newsItem, index) => (
            <div key={index} className="line_container" tabIndex={index + 1}>
              <span className="date">{formatDate(newsItem?.Date)}</span>
              <div className="line"></div>
              <a className="title" href={newsItem?.PostUrl}>
                {newsItem?.Title}
              </a>
            </div>
          ))}
        </div>
        <div className={`show_more_text`} onClick={() => setShowMore(!showMore)}>
          {showMore ? translations.showLess : translations.showMore}
        </div>
        <div id="news_component" className={`news_component${showMore ? ' open' : ' close'}`}>
          <div className={`news_bar_container bottom${showMore ? ' open' : ' close'}`}>
            {(data || []).map((newsItem, index) => (
              <a key={index} className={`line_container`} href={newsItem?.PostUrl}>
                <span className="date">{formatDate(newsItem?.Date)}</span>
                <div className="line"></div>
                <span className="title" title={newsItem?.Title}>
                  {newsItem?.Title}
                </span>
              </a>
            ))}
            <div className={`line_container_see_more`}>
              <a className={`see_more_link`} href={showMoreLink}>
                <span>{translations.seeMoreUpdates}</span>
                <div className="arrow"></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <WithRtl lazyElement={RTL} />
      {renderByDevice()}
    </>
  );
};
export default NewsBar;
